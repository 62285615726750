/**
 * @todo
 * - progressive app (offline use)
 * - Нужно сложные буквы показывать почаще, как и слова
 * - слова нужно разбить на кусочки по 7 штук, показывать с первой группу, до тех пор, пока она не будет проработана, затем показывать следующую группу, и т.д. Т.о., группы должны быть помечены как "пройденные", слова должны иметь вес (чем труднее, тем тяжелее и, следовательно, чаще показывать), но слова из предыдущих групп тоже надо иногда показывать, т.е. выборка должна осуществляться только из пройденных групп + текущая. 
 * - запоминание прогресса (сложность и пройденные группы) на клиенте и (когда будет авторизация) сохранение на сервере
 * - в горизонтальном положении кнопка "Дальше" справа
 */


import React from 'react';
import './App.scss';
import './css/icons.css';
import helper from './helper';
import Settings from "./Settings";
let data = require('./data.json');

const localStorageId = "uchis-chitat.settings"

data = helper.parseData(data)

function rand(min, max) {
  return Math.floor(Math.random() * (max-min) + min)
}

//random capitalizing the first letter
function randomCapital(word) {
  const probab = 1/4
  let rand = Math.random()
  if (rand < probab) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }
  return word
}

function getLocalStorage() {
  return helper.getLocalStorage(localStorageId) ||
    {
      progress: {
        letters:   [], //буквы
        syllables: [], //слоги
        word3:     [], //слова 3
        word4:     [], //слова 4
      }
    }
}


function getCurrentGroup(what) {
  const arr = data[what]
  const settings = getLocalStorage()
  const progress = settings.progress[what]
  for (let i = 0; i < arr.length; i++) {
    if (!progress[i] && (i === 0 || progress[i-1])) return i
  }
  return -1
}

class App extends React.Component {

  constructor() {
    super()
    this.state = {
      train: "",
      word: "",
      section: "letters",
      orientation: "vertical",
      view: "main" //"settings"
      // view: "settings"
    }
  }

  getWord() {
    const {train} = this.state
    let { word: currentWord } = this.state
    currentWord = currentWord.toLowerCase
    const cur = getCurrentGroup(train)
    let arr
    if (cur > -1) {
      arr = data[train][cur]
    } else {
      arr = data[train].flat()
    }
    let word = arr[rand(0, arr.length)]
    //avoid duplicate words
    while (word === currentWord) word = arr[rand(0, arr.length)]
    word = randomCapital(word)
    this.setState({ word })
  }

  setSection(section) {
    const {train} = this.state;
    if (train !== "") {
      this.setState({
        train: section,
        section
      }, this.getWord)
    } else {
      this.setState({ section })
    }
  }

  componentDidMount() {
    //orientation change listener
    window.addEventListener("orientationchange", (event) => {
      const angle = event.target.screen.orientation.angle
      let orientation = "horizontal"
      if (angle === 0 || angle === 180) orientation = "vertical"
      this.setState({ orientation })
    })
  }

  render() {
    const { train, word, section, orientation, view } = this.state;

    if (view === "settings") return (
      <div className={`App noselect ${orientation}`}>
        <Settings setAppState={(state) => this.setState(state)} />
      </div>
    )

    if (view === "main") return (
      <div className={`App noselect ${orientation}`}>
        <div className="top">
          {/* буквы */}
          <div className={`button ${section==="letters" ? "active":""}`} onClick={() => this.setSection("letters")}>Буквы</div>

          {/* слоги */}
          <div className={`button ${section==="syllables" ? "active":""}`} onClick={() => this.setSection("syllables")}>Слоги</div>

          {/* слова (3) */}
          <div className={`button ${section==="word3" ? "active":""}`} onClick={() => this.setSection("word3")}>Слова (3)</div>

          {/* слова (4) */}
          <div className={`button ${section==="word4" ? "active":""}`} onClick={() => this.setSection("word4")}>Слова (4)</div>

          {/* установки */}
          <div className="right"><div onClick={
            () => this.setState({view: "settings"})
          }><i className="icon icon-sliders"></i></div></div>
        </div>
        <div className="center">

          {train === "" && <div className="logo">Учись читать!</div>}
          
          {train === "word4" && <div className="word" dangerouslySetInnerHTML={{__html: word}}></div>}

          {train !== "" && train !== "word4" && <div className="word">{word}</div>}

        </div>
        <div className="bottom">

          {train === "" && <div className="button" onClick={() => {
            this.setState({train: section}, this.getWord)
          }}>Начнем</div>}

          {train !== "" && <div className="button" onClick={() => {
            this.getWord()
          }}>Дальше</div>}
        </div>
      </div>
    );
  }
}

export default App;
