
import React from 'react';
import helper from './helper';
let vocab = require('./data.json'); //already parsed in App.js

const localStorageId = "uchis-chitat.settings"

/** @todo save and retrieve from local store */
const settings = helper.getLocalStorage(localStorageId) ||
  {
    progress: {
      letters:   [], //буквы
      syllables: [], //слоги
      word3:     [], //слова 3
      word4:     [], //слова 4
    }
  }

function getCurrentGroup(what) {
  const arr = vocab[what]
  const progress = settings.progress[what]
  for (let i = 0; i < arr.length; i++) {
    if (!progress[i] && (i === 0 || progress[i-1])) return i
  }
  return -1
}

function icons (what, idx) {
  const arr = settings.progress[what]
  const isCurrent = (idx === getCurrentGroup(what))

  const hidden = isCurrent ? {} : {visibility:"hidden"}

  return <>
    <i className="icon icon-book-open now" style={hidden}></i>
    {arr[idx] && <i className="icon icon-check done"></i>}
    {!arr[idx] && <i className="icon icon-check-empty not-done"></i>}
  </>
}

export default class Settings extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      collapsed: {
        letters: true,
        syllables: true,
        word3: true,
        word4: true
      }
    }
  }

  collapse(what) {
    const { collapsed } = this.state
    collapsed[what] = !collapsed[what]
    this.setState(collapsed)
  }
  
  toggleDone(what, idx) {
    settings.progress[what][idx] = !settings.progress[what][idx]
    this.setState({x:Math.random()});
    window.localStorage.setItem(localStorageId, JSON.stringify(settings))
  }

  render() {
    const { collapsed } = this.state
    const { setAppState } = this.props

    return <div className="comp-settings">
      {/* back button */}
      <div className="backButton" onClick={() => setAppState({view: "main"})}>←</div>

      <h1>Установки</h1>

      {/* буквы */}
      <div className={`group ${collapsed.letters ? "collapsed" : ""}`}>
        <div className="title" onClick={() => this.collapse("letters")}>
          <i className="icon icon-angle-down down"></i>
          <i className="icon icon-angle-right right"></i>
          Буквы
        </div>
        {vocab.letters.map((a, id) => {
          return <div className="subgroup" key={id} onClick={() => this.toggleDone("letters", id)}>
            {icons("letters", id)}
            {
              a.map((b, id1) => (
                <span key={id1}>
                  {b}{id1 < a.length-1 && ","} 
                </span>
              ))
            }</div>
        })}
      </div>

      {/* слоги */}
      <div className={`group ${collapsed.syllables ? "collapsed" : ""}`}>
        <div className="title" onClick={(e) => this.collapse("syllables")}>
          <i className="icon icon-angle-down down"></i>
          <i className="icon icon-angle-right right"></i>
          Слоги
        </div>
        {vocab.syllables.map((a, id) => {
          return <div className="subgroup" key={id} onClick={() => this.toggleDone("syllables", id)}>
            {icons("syllables", id)}
            {a.map((b, id1) => (
              <span key={id1}>{b}{id1 < a.length-1 && ","} </span>
            ))
          }</div>
        })}
      </div>

      {/* слова3 */}
      <div className={`group ${collapsed.word3 ? "collapsed" : ""}`}>
        <div className="title" onClick={() => this.collapse("word3")}>
          <i className="icon icon-angle-down down"></i>
          <i className="icon icon-angle-right right"></i>
          Слова (3)
        </div>
        {vocab.word3.map((a, id) => {
          return <div className="subgroup" key={id} onClick={() => this.toggleDone("word3", id)}>
          {icons("word3", id)}
          {
            a.map((b, id1) => <span key={id1}>{b}{id1 < a.length-1 && ","} </span>)
          }</div>
        })}
      </div>

      {/* слова4 */}
      <div className={`group ${collapsed.word4 ? "collapsed" : ""}`}>
        <div className="title" onClick={() => this.collapse("word4")}>
          <i className="icon icon-angle-down down"></i>
          <i className="icon icon-angle-right right"></i>
          Слова (4)
        </div>
        {vocab.word4.map((a, id) => {
          return <div className="subgroup" key={id} onClick={() => this.toggleDone("word4", id)}>
          {icons("word4", id)}
          {
            a.map((b, id1) => <span key={id1}><span dangerouslySetInnerHTML={{__html:b}}></span>{id1 < a.length-1 && ","} </span>)
          }</div>
        })}
      </div>

    </div>
  }
}
