module.exports = {

    //data imported from ./data.json
    parseData: (data) => {
        data.letters[0] = data.letters[0].split("")
        data.syllables.forEach((val, idx) => data.syllables[idx] = data.syllables[idx].split(","))
        data.word3.forEach((val, idx) => data.word3[idx] = data.word3[idx].split(","))
        data.word4.forEach((val, idx) => data.word4[idx] = data.word4[idx].split(","))
        return data
    },

    
    getLocalStorage: (id) => {
        let item = window.localStorage.getItem(id)
        if (item) try {
            item = JSON.parse(window.localStorage.getItem(id))
            return item
        } catch(e) {}
        return undefined
    }
}